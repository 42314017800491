import React, { createContext, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import UploadTicket from './routes/UploadTicket/UploadTicket'
import MobileNavbar from './components/Navbar/MobileNavbar'
import ShareTicketv2 from './routes/ShareTicketv2/ShareTicketv2'
import LoadSession from './routes/LoadSession/LoadSession'
import { SocketProvider } from './websockets/SocketContext'

import './App.css'

export const TicketContext = createContext<any>(undefined)
function App() {
  const [isOpenVerificationModal, setIsOpenVerificationModal] = useState<boolean>(false)

  return (
    <div className="App">
      <BrowserRouter>
      <SocketProvider>
        <TicketContext.Provider value={{ isOpenVerificationModal, setIsOpenVerificationModal }}>
          <MobileNavbar />
          <Routes>
            <Route path="/" element={<UploadTicket />} />
            <Route path="/upload-ticket" element={<UploadTicket />} />
            <Route path="/share-ticket/:sessionid/:user" element={<ShareTicketv2 />} />
            <Route path="/load/:sessionid" element={<LoadSession />} />
            <Route path="*" element={<UploadTicket />} />
          </Routes>
        </TicketContext.Provider>
      </SocketProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
