import React, { ChangeEvent, useContext, useState } from 'react'
import { UPLOAD_API_URL } from '../../config'
import { useDispatch } from 'react-redux'
import { addSessionId } from '../../store/Session'
import { addTicket } from '../../store/Ticket'
import TicketVerificationModal from '../../components/TicketVerificationModal/TicketVerificationModal'
import { TicketContext } from '../../App'
import WaitProgressBar from '../../components/WaitProgressBar/WaitProgressBar'
import useRemoveTicketAndSessionId from '../../hooks/useRemoveTicket'
import { addSubFixForDuplicated } from '../../helpers/getCanonicalName'
import { TicketItems } from '../../types'

import './UploadTicket.css'

const UploadTicket = () => {
  const [error, setError] = useState<string>('')
  const [selectedFile, setSelectedFile] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { isOpenVerificationModal, setIsOpenVerificationModal } = useContext(TicketContext) as any
  const dispatch = useDispatch()

  useRemoveTicketAndSessionId()

  const handleSentImage = async (e: ChangeEvent<HTMLInputElement>) => {
    let file: File
    setError('')
    if (e.target.files) {
      file = e.target.files[0]
      setSelectedFile(e.target.files[0])
    } else return

    window.localStorage.clear()
    setError('')
    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append('image', file as any)

      const response = await fetch(UPLOAD_API_URL, {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        const data: { ticket: TicketItems[], session_id: string } = await response.json()
        console.log('File uploaded successfully. Ticket response:', data?.ticket)
        const productNames: string[] = data.ticket.map((t: any) => t.product_description)
        const productArray: string[] = addSubFixForDuplicated(productNames)
        const modifiedTicket: TicketItems[] = data.ticket.map((item: TicketItems, i: number) => ({ ...item, uniqueId: productArray[i] }))
        dispatch(addTicket(modifiedTicket))
        const sessionId = data.session_id ?? "1234_id"
        dispatch(addSessionId(sessionId))
        console.log("For SessionID:", data.session_id, ' Ticket added to store:', modifiedTicket)
        setIsOpenVerificationModal(true)
      } else if (response.status === 500) {
        setError('No conseguimos reconocer el contenido del ticket, sube otra foto por favor!')
        console.error('No conseguimos reconocer el contenido del ticket, sube otra foto por favor!')
      } else {
        setError('Failed to upload file')
        console.error('Failed to upload file', response.status)
      }
    } catch (error: any) {
      console.error('Error uploading file:', error.message)
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <main className="upload-ticket-container">
      {isOpenVerificationModal && <TicketVerificationModal setIsOpen={setIsOpenVerificationModal}/>}
      <h1>Reparte tu ticket</h1>
      <div className="input-ticket-container">
        <button className="select-ticket-button"> {selectedFile ? 'Ticket seleccionado' : 'Sube el ticket'}</button>
        <input type="file" accept="image/*" className="input-file" onChange={handleSentImage} />
      </div>
      <p>**Para mejorar el funcionamiento de la app se recomienda que el ticket esté apoyado sobre una superficie plana para la foto.</p>
      {isLoading && <WaitProgressBar />}
      {error && <span>{error}</span>}
    </main>
  )
}

export default UploadTicket
