import { createSlice } from '@reduxjs/toolkit'
import { ProductsForClients } from '../types'

const productsForClientsSlice = createSlice({
  name: 'productsForClients',
  initialState: (): ProductsForClients => {
    try {
      const productsLocalStorage = localStorage.getItem('productsForClients')
      if (productsLocalStorage) {
        return JSON.parse(productsLocalStorage)
      }
      return []
    } catch (error) {
      console.error('Error getting products from local storage', error)
    }
    return []
  },
  reducers: {
    updateProducts: (state, action) => action.payload,
  },
})

// export default store
const { actions, reducer } = productsForClientsSlice
export const { updateProducts } = actions
export default reducer
