export const getCanonicalName = (id: string) => {
  let fixId: string = id
  if (id.includes('_')) {
    fixId = id.split('_')[0]
  } else if (id.includes('-')) {
    fixId = id.split('-')[0]
  }
  return fixId
}

export const addSubFixForDuplicated = (products: string[]): string[] => {
  const seen: Map<string, number> = new Map()
  const productsModified = products.map((name: string) => {
    if (seen.has(name)) {
      const count = seen.get(name) as number
      seen.set(name, count + 1)
      return `${name}_${count}`
    } else {
      seen.set(name, 1)
      return name
    }
  })
  return productsModified
}