import { TicketItems } from '../../types'
import './SummaryTicketClients.css'

interface SummaryTableHeadProps {
  clientName: string
  products: TicketItems[]
  ticketWithFindings: TicketItems[]
}
const SummaryTableHead = ({ clientName, products, ticketWithFindings }: SummaryTableHeadProps) => {
  console.log(ticketWithFindings)
  const totalPrice = products.reduce((acc, product) => {
    const repeatedTimes: number = ticketWithFindings.find((p) => p.uniqueId === product.uniqueId)?.repeteadTimes || 1
    const sharedPrices: number = product.unit_price / repeatedTimes
    return acc + sharedPrices
  }, 0)
  console.log('totalPrice', totalPrice)
  return (
    <thead key={clientName}>
      <tr>
        <th>{clientName} </th>
        <th>{totalPrice?.toFixed(2)} € </th>
      </tr>
    </thead>
  )
}

export default SummaryTableHead
