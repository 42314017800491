import logo from './tasty-pay-icon.png'
import info from "./info.svg"
import downArrow from "./downArrow.svg"
import rightArrow from "./rightArrow.svg"
import clipboard from "./clipboard.svg"
export const imgs = {
  logo,
  info,
  downArrow,
  rightArrow,
  clipboard
}