// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-ticket-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Poppins;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--dark-blue);
}

.summary-ticket-table th {
  font-size: 16px;
  text-align: right;
}

.summary-ticket-table th:first-child {
  text-align: left;
}

.summary-ticket-table td {
  padding: 5px 0px;
  font-size: 14px;
  text-align: right;
}

.summary-ticket-table td:first-child {
  text-align: left;
}

.back-to-share {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 10px;
}

.back-to-share button {
  width: 250px;
  text-align: center;
  border: none;
  padding: 3px;
  background: var(--dark-blue);
  color: var(--white-blue);
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/SummaryTicketClients/SummaryTicketClients.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,oBAAoB;EACpB,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,4BAA4B;EAC5B,wBAAwB;EACxB,eAAe;AACjB","sourcesContent":[".summary-ticket-table {\n  width: 100%;\n  border-collapse: collapse;\n  font-family: Poppins;\n  margin-bottom: 20px;\n  border-bottom: 2px solid var(--dark-blue);\n}\n\n.summary-ticket-table th {\n  font-size: 16px;\n  text-align: right;\n}\n\n.summary-ticket-table th:first-child {\n  text-align: left;\n}\n\n.summary-ticket-table td {\n  padding: 5px 0px;\n  font-size: 14px;\n  text-align: right;\n}\n\n.summary-ticket-table td:first-child {\n  text-align: left;\n}\n\n.back-to-share {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  margin-top: 20px;\n  padding-bottom: 10px;\n}\n\n.back-to-share button {\n  width: 250px;\n  text-align: center;\n  border: none;\n  padding: 3px;\n  background: var(--dark-blue);\n  color: var(--white-blue);\n  font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
