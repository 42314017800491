import { createSlice } from '@reduxjs/toolkit'

const ticketSlice = createSlice({
  name: 'ticket',
  initialState: window.localStorage.getItem('ticket') ? JSON.parse(window.localStorage.getItem('ticket') as string) : [] as any,
  reducers: {
    addTicket: (state, action) => [...action.payload] as any,
    removeTicket: (state, action) => [],
  },
})

// export default store
const { actions, reducer } = ticketSlice
export const { addTicket, removeTicket } = actions
export default reducer
