
import { imgs } from '../../images'
import { TicketItems } from '../../types'
import { useContext } from 'react'
import { TicketContext } from '../../App'
import { useSelector } from 'react-redux'

import './MobileNavbar.css'


const MobileNavbar = () => {
  const ticket: TicketItems[] = useSelector((state: any) => state.ticket)
  const totalAmount: number = (ticket ? ticket?.reduce((acc, item) => acc + item.unit_price, 0) : undefined) as number
  const { setIsOpenVerificationModal } = useContext(TicketContext) as any
  const isTicket: boolean = ticket?.length > 0
  return (
    <nav>
      <div className="mobile-navbar">
        <img src={imgs.logo} alt="Tasty Pay" />
        {totalAmount !== undefined && totalAmount > 0 && <span>Total: {totalAmount?.toFixed(2)} €</span>}
        {isTicket && <button onClick={() => setIsOpenVerificationModal(true)}>Editar ticket</button>}
      </div>
    </nav>
  )
}

export default MobileNavbar
