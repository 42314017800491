import { useSelector } from 'react-redux'
import { imgs } from '../../images'
import { WhatsappIcon, WhatsappShareButton } from 'react-share'
import { useContext, useState } from 'react'
import { SocketContext } from '../../websockets/SocketContext'
import { BACKEND_URL, FRONT_URL_TO_SHARE } from '../../config'
import { adaptMapToSend } from '../../helpers/manageMapForWebsocket'
import { TicketItems } from '../../types'

import './WhatsAppShare.css'

interface WhatsAppShareProps {
  productsForClients: Map<string, TicketItems[]>
}
const WhatsAppShare = ({ productsForClients }: WhatsAppShareProps) => {
  const sessionId: string = useSelector((state: any) => state.sessionId)
  const url = FRONT_URL_TO_SHARE + 'load/' + sessionId
  const [copied, setCopied] = useState<boolean>(false)
  const { connect } = useContext(SocketContext) as any
  const ticket: TicketItems[] = useSelector((state: any) => state.ticket)
  const namesForClients: string[] = useSelector((s: any) => s.clientsName)

  const handleShare = async () => {
    try {
      const messageObj = adaptMapToSend(productsForClients, namesForClients)
      const body = JSON.stringify({ list_of_clients: namesForClients, ticket, products_for_clients: messageObj })
      const headers = { 'Content-Type': 'application/json' }
      const options = { method: 'POST', headers, body }
      await fetch(`${BACKEND_URL}/session-info/` + sessionId, options)
    } catch (error) {
      console.error('Error sharing session', error)
    }
    connect('master', sessionId)
  }

  const handleCopy = async () => {
    navigator.clipboard.writeText(url)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
    await handleShare()
  }
  return (
    <section id="whatsapp-share">
      <WhatsappShareButton url={`${url}`} onClick={handleShare}>
        <WhatsappIcon size={32} round={true} />
        <span>Reparte con el resto</span>
      </WhatsappShareButton>
      <button className="copy-link" onClick={handleCopy}>
        <img src={imgs.clipboard} alt="clipboard" />
        {!copied ? <span>Copiar enlace</span> : <span>Copiado!</span>}
      </button>
    </section>
  )
}

export default WhatsAppShare
