// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#whatsapp-share {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 15px;
}

#whatsapp-share button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 40px;
  gap: 10px;
  border-radius: 4px;
  outline: 1px solid var(--dark-blue);
}

#whatsapp-share span {
  font-family: Poppins;
  font-weight: 500;
  font-size: 1rem;
  color: var(--dark-blue);
}

#whatsapp-share .copy-link {
  border: none;
  width: 250px;
  height: 40px;
  gap: 10px;
  border-radius: 4px;
  outline: 1px solid var(--dark-blue);
  background: transparent;
  cursor: pointer;
}

#whatsapp-share .copy-link img {
  width: 32px;
}`, "",{"version":3,"sources":["webpack://./src/components/WhatsAppShare/WhatsAppShare.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;EACX,SAAS;EACT,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,SAAS;EACT,kBAAkB;EAClB,mCAAmC;AACrC;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,SAAS;EACT,kBAAkB;EAClB,mCAAmC;EACnC,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,WAAW;AACb","sourcesContent":["#whatsapp-share {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  gap: 10px;\n  padding: 10px;\n  box-sizing: border-box;\n  margin-top: 15px;\n}\n\n#whatsapp-share button {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 250px;\n  height: 40px;\n  gap: 10px;\n  border-radius: 4px;\n  outline: 1px solid var(--dark-blue);\n}\n\n#whatsapp-share span {\n  font-family: Poppins;\n  font-weight: 500;\n  font-size: 1rem;\n  color: var(--dark-blue);\n}\n\n#whatsapp-share .copy-link {\n  border: none;\n  width: 250px;\n  height: 40px;\n  gap: 10px;\n  border-radius: 4px;\n  outline: 1px solid var(--dark-blue);\n  background: transparent;\n  cursor: pointer;\n}\n\n#whatsapp-share .copy-link img {\n  width: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
