// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#share-ticket-v2 .summary-clients {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 3px;
  flex-wrap: wrap;
}

.summary-clients article {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap:0px;
  box-sizing: border-box;
}

.not-selected {
  padding: 5px;
  border: none;
  background-color: #f5f5f5;
}
.selected {
  padding-bottom: 0px;
  border-bottom: 2px solid var(--dark-blue);
}

.assign-items-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px;
}
.summary-clients article strong {
  font-weight: 700;
  font-size: 11px;
  font-family: var(--font);
  color: var(--dark-blue);
}
.summary-clients article span {
  font-weight: 600;
  font-size: 11px;
  font-family: var(--font);
  color: var(--dark-blue);
}
`, "",{"version":3,"sources":["webpack://./src/components/ClientsSumary/ClientsSummary.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,qBAAqB;EACrB,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,OAAO;EACP,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;AAC3B;AACA;EACE,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,uBAAuB;EACvB,sBAAsB;EACtB,QAAQ;EACR,eAAe;EACf,WAAW;EACX,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,wBAAwB;EACxB,uBAAuB;AACzB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,wBAAwB;EACxB,uBAAuB;AACzB","sourcesContent":["@import url('/src/App.css');\n\n#share-ticket-v2 .summary-clients {\n  display: flex;\n  justify-content: center;\n  align-items: baseline;\n  gap: 3px;\n  flex-wrap: wrap;\n}\n\n.summary-clients article {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  gap:0px;\n  box-sizing: border-box;\n}\n\n.not-selected {\n  padding: 5px;\n  border: none;\n  background-color: #f5f5f5;\n}\n.selected {\n  padding-bottom: 0px;\n  border-bottom: 2px solid var(--dark-blue);\n}\n\n.assign-items-container {\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-direction: column;\n  gap: 5px;\n  flex-wrap: wrap;\n  width: 100%;\n  padding: 5px;\n}\n.summary-clients article strong {\n  font-weight: 700;\n  font-size: 11px;\n  font-family: var(--font);\n  color: var(--dark-blue);\n}\n.summary-clients article span {\n  font-weight: 600;\n  font-size: 11px;\n  font-family: var(--font);\n  color: var(--dark-blue);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
