// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  scale: 0.8
}

#text-inside {
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#text-inside strong {
  color: var(--dark-blue);
  width: 60%;
  text-align: center;
  font-family: var(--font);
}
`, "",{"version":3,"sources":["webpack://./src/components/WaitProgressBar/WaitProgressBar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb;AACF;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,OAAO;EACP,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,UAAU;EACV,kBAAkB;EAClB,wBAAwB;AAC1B","sourcesContent":[".progress-bar {\n  position: relative;\n  top: 0;\n  left: 0;\n  width: 100%;\n  z-index: 9999;\n  scale: 0.8\n}\n\n#text-inside {\n  position: absolute;\n  top: 40%;\n  left: 0;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n#text-inside strong {\n  color: var(--dark-blue);\n  width: 60%;\n  text-align: center;\n  font-family: var(--font);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
