import { ChangeEvent } from 'react'
import { THRESHOLD_FOR_WARNING } from '../../config'
import { getCanonicalName } from '../../helpers/getCanonicalName'
import { TicketItems } from '../../types'
export interface TicketItemsRowProps {
  item: TicketItems
  handleDescriptionChange: (e: ChangeEvent<any>, product_description: string) => void
  handlePriceChange: (e: ChangeEvent<any>, product_description: string) => void
}
const TicketItemRow = ({ item, handleDescriptionChange, handlePriceChange }: TicketItemsRowProps) => {
  const isEnoughConfidence = item.confidence >= THRESHOLD_FOR_WARNING
  return (
    <tr key={`${item.uniqueId}`}>
      <td className="description-column">
        {!isEnoughConfidence && <span>⚠️ </span>}
        <strong>x{item.repeteadTimes}</strong>
        <input
          type="text"
          key={`${item.uniqueId}`}
          value={getCanonicalName(item.product_description)}
          onChange={(e: ChangeEvent) => handleDescriptionChange(e, item.uniqueId as string)}
        />
      </td>
      <td className="second-column">
        <input type="text" value={item?.unit_price?.toFixed(2)} onChange={(e: ChangeEvent) => handlePriceChange(e, item.uniqueId as string)} />
      </td>
    </tr>
  )
}

export default TicketItemRow
