import { createContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { WEBSOCKET_URL } from '../config'
import { ProductsForClients } from '../types'

export const SocketContext = createContext(null) as any

export const SocketProvider = ({ children }: { children: any }) => {
  const [socket, setSocket] = useState<WebSocket | null>(null)
  const [error, setError] = useState<any>(undefined)
  const [receivedData, setReceivedData] = useState<any>(undefined)
  const { user, sessionid } = useParams<{ user: string; sessionid: string }>()

  const cleanReceivedData = () => {
    setReceivedData(undefined)
  }

  const connect = (userId: string, sessionId: string) => {
    try {
      setSocket((prevSockect: WebSocket | null) => {
        if (!prevSockect) return new WebSocket(`${WEBSOCKET_URL}${sessionId}?user_id=${userId}`)
        return prevSockect
      })
    } catch (error) {
      console.error('Error connecting to websocket', error)
    }
  }

  const sendUpdateProductsForClients = (productsForClients: ProductsForClients) => {
    const message = JSON.stringify({ products: productsForClients, state: 'update' })
    socket?.send(message)
  }

  // //* useEffect to re-stablish connection
  // useEffect(() => {
  //   if (user && socket?.readyState === WebSocket.CLOSED) {
  //     setSocket((prevSockect: WebSocket | null) => {
  //       if (!prevSockect) return new WebSocket(`${WEBSOCKET_URL}${sessionid}?user_id=${user}`)
  //       return prevSockect
  //     })
  //   }
  // }, [sessionid, setSocket, user, socket?.readyState])

  // Open connection
  useEffect(() => {
    socket?.addEventListener('open', (event: Event) => {
      console.log('Socket open connection')
    })

    return () => {
      socket?.removeEventListener('open', () => console.log('unsubscribed'))
    }
  }, [socket])

  // Error handler
  useEffect(() => {
    socket?.addEventListener('error', (event: Event) => {
      console.error('Error in socket with user', event)
      setError(event)
    })

    return () => {
      socket?.removeEventListener('error', () => console.log('unsubscribed'))
    }
  }, [socket])

  // listen messages
  useEffect(() => {
    socket?.addEventListener('message', (event) => {
      console.log('RECEIVED MESSAGE: ', event.data)
      setReceivedData(event.data)
    })
    return () => {
      socket?.removeEventListener('message', () => console.log('unsubscribed'))
    }
  }, [socket])


  useEffect(() => {
    const handleSocketClose = (event: any) => {
      console.warn('WebSocket closed for' + sessionid)
      const newSocket = new WebSocket(`${WEBSOCKET_URL}${sessionid}?user_id=${user}`)
      setSocket(newSocket)
    }

    socket?.addEventListener('close', handleSocketClose)

    return () => {
      socket?.removeEventListener('close', handleSocketClose)
    }
  }, [socket, sessionid, user])

  return <SocketContext.Provider value={{ connect, sendUpdateProductsForClients, cleanReceivedData, socket, receivedData, error }}>{children}</SocketContext.Provider>
}
