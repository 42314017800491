

//* Returns the tickets grouped by product description.
import { TicketItems } from "../types"

//* Add a new field calles repeteadTimes with the number of times that product is repeated
export const groupTicketItems = (ticket: TicketItems[]): TicketItems[] => {
  const groupedTicket: TicketItems[] = []

  ticket.forEach((item: TicketItems) => {
    const itemGroup = ticket.filter((group: TicketItems) => group.product_description === item.product_description)
    if (!groupedTicket.some((group: TicketItems) => group.product_description === itemGroup[0].product_description))
      groupedTicket.push({ ...itemGroup[0], repeteadTimes: itemGroup.length })
  })
  return groupedTicket
}
