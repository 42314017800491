
import { TicketItems } from '../../types'
import './ClientsSummary.css'
interface ClientsSummaryProps {
  namesForClients: string[]
  currentClient: string
  productsForClients: Map<string, TicketItems[]>
}

const ClientsSummary = ({ namesForClients, currentClient, productsForClients }: ClientsSummaryProps) => {
  return (
    <div className="summary-clients">
      {namesForClients?.map((clientName: string) => (
        <ClientSummaryItem
          key={clientName}
          clientName={clientName}
          currentClient={currentClient}
          productsForClients={productsForClients}
        />
      ))}
    </div>
  )
}

interface ClientsSummaryItemProps {
  clientName: string
  currentClient: string
  productsForClients: Map<string, TicketItems[]>
}

const ClientSummaryItem = ({ clientName, currentClient, productsForClients }: ClientsSummaryItemProps) => {
  const currentClientProducts: TicketItems[] = productsForClients?.get(clientName) || []
  // With this loop we check how many times a product is assigned to other clients to share the cost between all of them
  const payAmount: number = currentClientProducts.reduce((acc, clientItem) => {
    let repeatedAmount: number = 1
    productsForClients.forEach((value: TicketItems[], key: string) => {
      const assigned: TicketItems[] = value.filter((i: TicketItems) => i.uniqueId === clientItem.uniqueId)
      if (assigned.length > 0 && key !== clientName) {
        repeatedAmount += 1
      }
    })
    return acc + clientItem.unit_price / repeatedAmount
  }, 0)

  return (
    <article key={clientName} className={currentClient === clientName ? 'selected' : 'not-selected'}>
      <strong>{clientName}</strong>
      <span>{payAmount?.toFixed(2)}€</span>
    </article>
  )
}
export default ClientsSummary
