import { useEffect } from 'react'
import { removeTicket } from '../store/Ticket'
import { useDispatch } from 'react-redux'
import { removeSessionId } from '../store/Session'

const useRemoveTicketAndSessionId = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    localStorage.removeItem('ticket')
    localStorage.removeItem('sessionId')
    dispatch(removeTicket({}))
    dispatch(removeSessionId({}))
  }, [dispatch])
}

export default useRemoveTicketAndSessionId
