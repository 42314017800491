import { useNavigate, useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { SocketContext } from '../../websockets/SocketContext'
import Spinner from '../../components/Spinner/Spinner'
import useGetSessionInfo from '../../hooks/useGetClients'
import { addSessionId } from '../../store/Session'
import { useDispatch } from 'react-redux'

import './LoadSession.css'

const LoadSession = () => {
  const { sessionid } = useParams<{ sessionid: string }>()
  const { connect } = useContext(SocketContext) as any
  const [clientId, setClientId] = useState<string | null>(null)
  const { clients, loading, ticket } = useGetSessionInfo(sessionid)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  //* useEffect to stablish connection
  useEffect(() => {
    if (sessionid && clientId) {
      dispatch(addSessionId(sessionid))
      connect(clientId, sessionid)
    }
  }, [sessionid, connect, clientId, dispatch])

  useEffect(() => {
    if (ticket.length > 0 && clientId && !loading) {
      navigate('/share-ticket/' + sessionid + '/' + clientId)
    }
  },[ticket, loading, navigate, sessionid, clientId])

  return (
    <section id="load-session">
      {(clients.length === 0 || loading) && (
        <div className="loading">
          <h1>Preparando datos</h1>
          <Spinner />
        </div>
      )}
      {clients.length > 0 && (
        <div className="clients">
          <h2>¿Quien eres tú?</h2>
          <ul>
            {clients.map((client: string) => (
              <ClientName key={client} client={client} setClientId={setClientId} />
            ))}
          </ul>
        </div>
      )}
    </section>
  )
}

const ClientName = ({ client, setClientId }: { client: string; setClientId: Function }) => {
  const handleClientId = () => {
    setClientId(client)
    window.localStorage.setItem('clientId', client)
  }
  return (
    <li>
      <button onClick={handleClientId}>{client}</button>
    </li>
  )
}

export default LoadSession
