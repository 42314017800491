import { useEffect, useState } from 'react'
import { BACKEND_URL } from '../config'
import { addTicket } from '../store/Ticket'
import { useDispatch } from 'react-redux'
import { addClientsName } from '../store/ClientsName'
import { updateProducts } from '../store/ProductsForClients'
import { ProductsForClients, TicketItems } from '../types'

interface ClientInfo {
  client_id: string
  available: boolean
}

interface SessionInfo {
  clients: ClientInfo[]
  ticket: TicketItems[]
  products_for_clients: ProductsForClients
}
const useGetSessionInfo = (sessionId: string | undefined) => {
  const [clients, setClients] = useState<string[]>([])
  const [ticket, setTicket] = useState<TicketItems[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchClients = async () => {
      const response = await fetch(BACKEND_URL + '/session-info/' + sessionId)
      if (response.status !== 200) throw new Error('Error fetching clients')
      const data: SessionInfo = await response.json()
      console.log('Session Info', data)
      setClients(data?.clients.filter((client) => (client.client_id !== 'master') && (client.available)).map((client) => client.client_id) || [])
      setTicket(data?.ticket)
      dispatch(addTicket(data?.ticket))
      dispatch(addClientsName(data?.clients.filter((client) => client.client_id !== 'master').map((client) => client.client_id) || []))
      dispatch(updateProducts(data?.products_for_clients))
    }

    try {
      setLoading(true)
      if (sessionId) {
        fetchClients()
      }
    } catch (error) {
      console.error('Error fetching clients', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [sessionId, dispatch])

  return { clients, ticket, loading, error }
}

export default useGetSessionInfo
