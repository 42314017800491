import { createSlice } from "@reduxjs/toolkit"

const userDataSlice = createSlice ({
  name: "userData",
  initialState: { table: null, restaurant: null,  price: 0, products: [] },
  reducers: {
    addUserData: (state, action) => ({
        price: state.price + action.payload.price,
        products: [...state.products, action.payload.product],
        table: action.payload.table,
        restaurant: action.payload.restaurant
    }) as any,
    removeUserData: (state, action) => ({
      price: state.price - action.payload.price,
      products: action.payload.products
  }) as any,
  },
})

// export default store
const { actions, reducer } = userDataSlice
export const { addUserData, removeUserData } = actions
export default reducer
