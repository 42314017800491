import { TicketItems } from "../types";


export const adaptMapToSend = (newProductsForClients: Map<string, TicketItems[]>, namesForClients: string[]) => {
  const messageObj: Array<{ name: string; products: TicketItems[] | undefined }> = []
  for (const name of namesForClients) {
    if (newProductsForClients.has(name)) {
      messageObj.push({ name, products: newProductsForClients?.get(name) })
    }
  }
  return messageObj
}
