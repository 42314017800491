import { useDispatch, useSelector } from 'react-redux'
import { updateProducts } from '../../store/ProductsForClients'
import { ProductsForClients, TicketItems } from '../../types'
import { useContext } from 'react'
import { SocketContext } from '../../websockets/SocketContext'

import './ProductList.css'


interface ProductItemListProps {
  item: TicketItems
  selectedItems: TicketItems[]
  setSelectedItems: (s: TicketItems[]) => void
  currentClient: string
}

const ProductItemList = ({ item, selectedItems, setSelectedItems, currentClient }: ProductItemListProps) => {
  const dispatch = useDispatch()
  const { sendUpdateProductsForClients } = useContext(SocketContext) as any
  const arrayProductsForClients: ProductsForClients = useSelector((s: any) => s.productsForClients)
  const clientsHaveThisItemAssigned: string[] = arrayProductsForClients
    .filter((client) => client.products.some((i: TicketItems) => i.uniqueId?.toLowerCase() === item?.uniqueId?.toLowerCase()))
    .map((i) => i.name)


  const handleCheckItem = (e: any) => {
    const value = e.target.value
    let newSelectedItemsForClient: TicketItems[] = []
    if (e.target.checked) {
      newSelectedItemsForClient = [...selectedItems, { ...item }]
    } else {
      newSelectedItemsForClient = selectedItems.filter((i: TicketItems) => i.uniqueId !== value)
    }

    setSelectedItems(newSelectedItemsForClient)
    const newArray: ProductsForClients = arrayProductsForClients.map((clientInfo) =>
      clientInfo.name === currentClient ? { name: clientInfo.name, products: newSelectedItemsForClient } : clientInfo
    )
    sendUpdateProductsForClients(newArray)
    dispatch(updateProducts(newArray))
  }

  const isChecked = (): boolean => selectedItems.some((i: TicketItems) => i.uniqueId === item.uniqueId)

  return (
    <li key={item.uniqueId}>
      <div>
        <input type="checkbox" className="checkbox" value={item.uniqueId} onChange={handleCheckItem} checked={isChecked()} />
        <span className="product-info">{item.product_description}</span>
        <span className="product-info">{item.unit_price?.toFixed(2)} €</span>
      </div>
      {clientsHaveThisItemAssigned.length > 0 && (
        <div className="clients-assigned">
          {clientsHaveThisItemAssigned.map((client: string) => (
            <span key={client} className={clientsHaveThisItemAssigned.length > 2 ? 'clients-5' : 'clients-2'}>
              {client}
            </span>
          ))}
        </div>
      )}
    </li>
  )
}

export default ProductItemList
