import { createSlice } from '@reduxjs/toolkit'

const sessionIdSlice = createSlice({
  name: 'sessionId',
  initialState: '' as string,
  reducers: {
    addSessionId: (state, action) => action.payload as string,
    removeSessionId: (state, action) => '',
  },
})

// export default store
const { actions, reducer } = sessionIdSlice
export const { addSessionId, removeSessionId } = actions
export default reducer
