import { useDispatch, useSelector } from 'react-redux'
import { addTicket } from '../../store/Ticket'
import { ProductsForClients, TicketItems } from '../../types'

interface NextPreviousButtonsProps {
  currentClient: string
  setCurrentClient: (s: string) => void
}

const NextPreviousButtons = ({ currentClient, setCurrentClient }: NextPreviousButtonsProps) => {
  const namesForClients: string[] = useSelector((s: any) => s.clientsName)
  const arrayProductsForClients: ProductsForClients = useSelector((s: any) => s.productsForClients)
  const ticket: TicketItems[] = useSelector((state: any) => state.ticket)
  const dispatch = useDispatch()
  const handleCurrentClient = (direction: string) => {
    const index: number = namesForClients.findIndex((name: string) => name === currentClient)
    if (direction === 'previous' && index === 0) {
      setCurrentClient(namesForClients[namesForClients.length - 1])
    } else if (direction === 'previous') {
      setCurrentClient(namesForClients[index - 1])
    } else if (direction === 'next' && index === namesForClients.length - 1) {
      setCurrentClient(namesForClients[0])
    } else {
      setCurrentClient(namesForClients[index + 1])
    }

    const tempReorderedTicket: TicketItems[] = []

    ticket.forEach((item: TicketItems) => {
      const productForCurrentClient = (arrayProductsForClients.find((client) => client.name === currentClient))?.products
      const itemIsAssigned = productForCurrentClient?.some((selectedItem: TicketItems) => selectedItem.uniqueId === item.uniqueId)
      if (itemIsAssigned) tempReorderedTicket.push(item)
      else tempReorderedTicket.unshift(item)
    })
    dispatch(addTicket(tempReorderedTicket))
    console.log('Ticket reordered', arrayProductsForClients)
  }
  return (
    <div className="next-container">
      <button onClick={() => handleCurrentClient('previous')}>Anterior cliente</button>
      <button onClick={() => handleCurrentClient('next')}>Siguiente cliente</button>
    </div>
  )
}

export default NextPreviousButtons
