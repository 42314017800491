import { ChangeEvent, useState } from 'react'
import TicketItemRow from './TicketItemRow'
import { groupTicketItems } from '../../helpers/ticketHelper'
import InputsNewItemRow from './InputsNewItemRow'
import { TicketItems } from '../../types'

import './TicketVerificationModal.css'


export interface TicketItemsTableProps {
  ticket: TicketItems[]
  handleDescriptionChange: (e: ChangeEvent<any>, product_description: string) => void
  handlePriceChange: (e: ChangeEvent<any>, product_description: string) => void
  setNewProducts: any
}

const TicketItemsTable = ({ ticket, handleDescriptionChange, handlePriceChange, setNewProducts }: TicketItemsTableProps) => {
  const groupedTicket: TicketItems[] = groupTicketItems(ticket)
  const [itemsToAdd, setItemToAdd] = useState<number>(0)
  const arrayForInputs: Array<number> = Array.from({ length: itemsToAdd })

  return (
    <table id="drag-and-drop-table">
      <thead>
        <tr>
          <th>Producto</th>
          <th>Precio/ud</th>
        </tr>
      </thead>
      <tbody>
        {groupedTicket.map((item: TicketItems, i: number) => (
          <TicketItemRow item={item} handleDescriptionChange={handleDescriptionChange} handlePriceChange={handlePriceChange} key={item.uniqueId} />
        ))}

        {itemsToAdd > 0 && (
          <>
            {arrayForInputs.map((_, i: number) => (
              <InputsNewItemRow key={i} setNewProducts={setNewProducts} newRowIndex={i} />
            ))}
          </>
        )}
        <tr>
          <td colSpan={2} className="add-column">
            <button className="add-btn" onClick={() => setItemToAdd((s: number) => s + 1)}>
              + Añade un elemento
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default TicketItemsTable
