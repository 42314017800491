

export const THRESHOLD_FOR_WARNING = .9

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
export const WEBSOCKET_URL = 'wss' + BACKEND_URL?.slice(5) + '/ws/'
export const IS_PRODUCTION: boolean = process.env.REACT_APP_PRODUCTION === 'PRO'
export const FRONT_URL_TO_SHARE: string = process.env.REACT_APP_FRONT_URL_TO_SHARE || "http://localhost:3000/"
const UPLOAD_ENDPOINT : string = '/process-receipt'
const UPLOAD_ENDPOINT_DEV : string = '/process-receipt' // '/process-receipt-dev'

const LOCAL_API_URL: string = 'http://localhost:8000'

export const UPLOAD_API_URL: string = IS_PRODUCTION ?  BACKEND_URL + UPLOAD_ENDPOINT : LOCAL_API_URL + UPLOAD_ENDPOINT_DEV
