import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addClientsName } from '../../store/ClientsName'
import { updateProducts } from '../../store/ProductsForClients'
import './ClientsNumberModal.css'

interface ClientsNumberModalProps {
  setShowClientsModal: (show: boolean) => void
}
const ClientsNumberModal = ({ setShowClientsModal }: ClientsNumberModalProps) => {
  const [clientsNumber, setClientsNumber] = useState<number>(2)
  const [showNames, setShowNames] = useState<boolean>(false)
  const namesForClients = useSelector((s: any) => s.clientsName)
  const [tempClientNames, setTempClientNames] = useState<string[]>(namesForClients)
  const dispatch = useDispatch()

  const handleClients = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setClientsNumber(parseInt(event.target.value))
  }

  const handleContinue = () => {
    dispatch(addClientsName(tempClientNames))
    const tempProductsForClients = tempClientNames.map((name) => ({ name, products: [] }))
    dispatch(updateProducts(tempProductsForClients))
    setShowClientsModal(false)
  }

  useEffect(() => {
    if (clientsNumber >= 2) {
      if (tempClientNames.length === 0) {
        setTempClientNames(Array.from({ length: clientsNumber }).map((_, index) => `Cliente-${[index + 1]}`))
      } else if (tempClientNames.length < clientsNumber) {
        setTempClientNames([
          ...tempClientNames,
          ...Array.from({ length: clientsNumber - tempClientNames.length }).map((_, index) => `Cliente-${[tempClientNames?.length + 1 + index]}`),
        ])
      } else if (tempClientNames.length > clientsNumber) {
        setTempClientNames(tempClientNames.slice(0, clientsNumber))
      }
    }
  }, [tempClientNames, clientsNumber])

  return (
    <div className="modal">
      <div className="modal-content-clients">
        <h2>¿Cuántas personas sois?</h2>
        <select className="select-clients" onChange={handleClients} value={clientsNumber}>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>
        <button className="button-for-names" onClick={() => setShowNames(!showNames)}>
          Rellenar nombres <i>(Opcional)</i>
        </button>

        {showNames && (
          <form>
            {Array.from({ length: clientsNumber }).map((_, index) => (
              <div key={index}>
                <label htmlFor={`client${index}`}>{index + 1}: </label>
                <input
                  type="text"
                  id={`client${index}`}
                  name={`client${index}`}
                  max={25}
                  onChange={(event) => {
                    const newNames = [...tempClientNames]
                    newNames[index] = event.target.value
                    setTempClientNames(newNames)
                  }}
                />
              </div>
            ))}
          </form>
        )}
        <button className="continue-button" onClick={handleContinue}>
          Continuar
        </button>
      </div>
    </div>
  )
}

export default ClientsNumberModal
