import { configureStore, createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import userDataReducer from './userDataSlice'
import ticketReducer, { addTicket, removeTicket } from './Ticket'
import clientsNameReducer, { addClientsName } from './ClientsName'
import sessionIdReducer, { addSessionId } from './Session'
import productsForClientsReducer, { updateProducts } from './ProductsForClients'

const localStorageMiddleware = createListenerMiddleware()

localStorageMiddleware.startListening({
  matcher: isAnyOf(addTicket, removeTicket, addClientsName, addSessionId, updateProducts),
  effect: (action, listenerApi) => {
    if (action.type === addTicket.type) {
      localStorage.setItem('ticket', JSON.stringify(action.payload))
    } else if (action.type === addClientsName.type) {
      localStorage.setItem('clientsName', JSON.stringify(action.payload))
    } else if (action.type === addSessionId.type) {
      localStorage.setItem('sessionId', action.payload as string)
    } else if (action.type === removeTicket.type) {
      localStorage.removeItem('ticket')
    } else if (action.type === updateProducts.type) {
      localStorage.setItem('productsForClients', JSON.stringify(action.payload))
    }
  },
})

const store = configureStore({
  reducer: {
    userData: userDataReducer,
    ticket: ticketReducer,
    sessionId: sessionIdReducer,
    clientsName: clientsNameReducer,
    productsForClients: productsForClientsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(localStorageMiddleware.middleware),
})

export default store
