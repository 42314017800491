

import { createSlice } from '@reduxjs/toolkit'

const clientsName = createSlice({
  name: 'clientsName',
  initialState: (): string[] => {
    try {
      const clientsLocalStorage = localStorage.getItem('clientsName')
      if (clientsLocalStorage) {
        return JSON.parse(clientsLocalStorage)
      } else {
        return []
      }
    }
    catch (error) {
      console.error('Error getting clients from local storage', error)
    }
    return []
  },
  reducers: {
    addClientsName: (state, action) => action.payload,
  },
})

// export default store
const { actions, reducer } = clientsName
export const { addClientsName } = actions
export default reducer
