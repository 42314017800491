import { useSelector } from 'react-redux'
import { ProductsForClients, TicketItems } from '../../types'
import SummaryTableHead from './SummaryTableHead'
import './SummaryTicketClients.css'

interface SummaryTicketClientsProps {
  setShowSummary: (showSummary: boolean) => void
}

const SummaryTicketClients = ({ setShowSummary }: SummaryTicketClientsProps) => {
  const productsForClients: ProductsForClients = useSelector((s: any) => s.productsForClients)
  const ticket: TicketItems[] = useSelector((s: any) => s.ticket)
  const ticketWithFindins = ticket.map((product) => {
    const founded: ProductsForClients = productsForClients.filter((client) => client.products.some((p) => p.uniqueId === product.uniqueId))
    return { ...product, repeteadTimes: founded.length === 0 ? 1 : founded.length}
  })

  return (
    <div id="ticket-verification-modal">
      <div className="ticket-verification-modal-content">
        <h3>Resumen del ticket</h3>
        <section>
          {productsForClients.map(({ name, products }) => (
            <table className="summary-ticket-table">
              <SummaryTableHead clientName={name} products={products} ticketWithFindings={ticketWithFindins} />
              <tbody>
                {products.map((p: TicketItems) => (
                  <tr>
                    <td>{`${p.product_description}`}</td>
                    <td>{`${p.unit_price.toFixed(2)}€`}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}

          <div className="back-to-share">
            <button onClick={() => setShowSummary(false)}>Volver a repartir</button>
          </div>
        </section>
      </div>
    </div>
  )
}

export default SummaryTicketClients
