// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
  width: 100%;
  height: 40px;
  position: relative;
  top: 0;
  height: 60px;
  overflow: hidden;
}
.mobile-navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5%;
  width: 100%;
  height: 60px;
  padding: 0 10px;
  background-color: var(--dark-blue);
  color: white;
  position: fixed;
  top: 0;
  z-index: 1000;
  overflow: hidden;
}

.mobile-navbar span {
  font-size: 1.3rem;
  font-family: Poppins;
  font-weight: 700;
  letter-spacing: -1px;
  color: var(--white-blue);
  border: 1px dashed var(--white-blue);
  padding: 5px;
  border-radius: 2px;

}
.mobile-navbar img {
  width: 65px;
  filter: drop-shadow(0px 0px 4px #ffc46d);
}

.mobile-navbar button {
  width: 70px;
  background-color: rgb(205, 205, 205);
  color: var(--dark-blue);
  font-size: 14px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 1.2;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar/MobileNavbar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,OAAO;EACP,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kCAAkC;EAClC,YAAY;EACZ,eAAe;EACf,MAAM;EACN,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,gBAAgB;EAChB,oBAAoB;EACpB,wBAAwB;EACxB,oCAAoC;EACpC,YAAY;EACZ,kBAAkB;;AAEpB;AACA;EACE,WAAW;EACX,wCAAwC;AAC1C;;AAEA;EACE,WAAW;EACX,oCAAoC;EACpC,uBAAuB;EACvB,eAAe;EACf,oBAAoB;EACpB,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB","sourcesContent":["nav {\n  width: 100%;\n  height: 40px;\n  position: relative;\n  top: 0;\n  height: 60px;\n  overflow: hidden;\n}\n.mobile-navbar {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  gap: 5%;\n  width: 100%;\n  height: 60px;\n  padding: 0 10px;\n  background-color: var(--dark-blue);\n  color: white;\n  position: fixed;\n  top: 0;\n  z-index: 1000;\n  overflow: hidden;\n}\n\n.mobile-navbar span {\n  font-size: 1.3rem;\n  font-family: Poppins;\n  font-weight: 700;\n  letter-spacing: -1px;\n  color: var(--white-blue);\n  border: 1px dashed var(--white-blue);\n  padding: 5px;\n  border-radius: 2px;\n\n}\n.mobile-navbar img {\n  width: 65px;\n  filter: drop-shadow(0px 0px 4px #ffc46d);\n}\n\n.mobile-navbar button {\n  width: 70px;\n  background-color: rgb(205, 205, 205);\n  color: var(--dark-blue);\n  font-size: 14px;\n  font-family: Poppins;\n  font-weight: 700;\n  line-height: 1.2;\n  padding: 5px 10px;\n  border-radius: 5px;\n  border: none;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
